/* @import url('https://fonts.googleapis.com/css2?family=Noto+Serif+Display:wght@200;300;400;500&family=Roboto&display=swap'); */


/* inspired by: https://css-tricks.com/responsive-data-tables/ */
.responsiveTable td .tdBefore {
    display: none;
  }
  
  @media screen and (max-width: 40em) {
    /*
      Force table elements to not behave like tables anymore
      Hide table headers (but not display: none; for accessibility)
    */
  
    .responsiveTable table,
    .responsiveTable thead,
    .responsiveTable tbody,
    .responsiveTable th,
    .responsiveTable td,
    .responsiveTable tr {
      display: block;
    }
  
    .responsiveTable thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }
  
    .responsiveTable tbody tr:not(:last-child) {
        margin-bottom: var(--chakra-sizes-10);
    }

    .responsiveTable tr:nth-of-type(odd) th, 
    .responsiveTable tr:nth-of-type(odd) td {
        background-color: white!important;
    }

    .responsiveTable tr td:nth-of-type(1) {
        background-color: var(--chakra-colors-secondary-100) !important;
    }

    .responsiveTable td:before  {
        float: left;
      }
  
    .responsiveTable td.pivoted {
      /* Behave like a "row" */
  
      border: none !important;
      position: relative;
      padding-left: calc(50% + 10px) !important;
      text-align: left !important;
      white-space: pre-wrap;
      overflow-wrap: break-word;
      border-bottom: 1px solid lightgrey !important;
    }
  
    .responsiveTable td .tdBefore {
      /* Now like a table header */
      position: absolute;
      display: block;
  
      /* Top/left values mimic padding */
      left: 1rem;
      width: calc(50% - 20px);
      white-space: pre-wrap;
      overflow-wrap: break-word;
      text-align: left !important;
      font-weight: 600;
    }

    /*
	    Label the data
	*/
	td:nth-of-type(2):before { content: "ITP"; }
	td:nth-of-type(3):before { content: "Reverificare siguranta"; }
	td:nth-of-type(4):before { content: "Reverficare poluare"; }
	td:nth-of-type(5):before { content: "Reverficare altele"; }
  }